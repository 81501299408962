:root {
  --exxon-blue: #85b2f9;
  --exxon-blue-2: #1e67a8;

  --exxon-white: #ececec;

  --exxon-gray-1: #495058;
  --exxon-gray-2: #bcbcbc20;
  --exxon-gray-3: #eeeeee;
}

.exxon-blue {
  color: var(--exxon-blue) !important;
}

.exxon-bg-white {
  background-color: var(--exxon-white) !important;
}

.exxon-bg-blue {
  background-color: var(--exxon-blue) !important;
}

.exxon-bg-gray {
  background-color: var(--exxon-gray-1) !important;
}

.p-menu,
.p-card,
.p-fieldset,
.p-dropdown,
.p-inputtext,
.p-multiselect,
.p-dialog-header,
.p-dialog-footer,
.p-dialog-content,
.p-fieldset-legend,
.p-dropdown-panel,
.p-button:not(.p-button-rounded) {
  @extend .border-noround;
}

.p-datatable-thead th,
.p-datatable-tbody td {
  @extend .text-sm;
}

.p-tabview-panels {
  @extend .p-0;
}

.p-button:focus {
  box-shadow: none;
}

// Fluidilogix - App Mobile

.p-toast {
  position: fixed !important;
  visibility: visible;
  z-index: 9999 !important;
}

body.barcode-scanner-active {
  visibility: hidden;
  --background: transparent;
  --ion-background-color: transparent;
}

// Show only the barcode scanner modal
.barcode-scanner-modal {
  visibility: visible;
}

@media (prefers-color-scheme: dark) {
  .barcode-scanner-modal {
    --background: transparent;
    --ion-background-color: transparent;
  }
}

::ng-deep .action-sheet-destructive {
  color: red !important;
}

.fluidilogix-alert-success {
  visibility: visible;
  position: absolute;
}

.fx-ion-button {
  @extend .text-xl, .font-bold;

  --color: var(--exxon-blue-2);
  --background: var(----exxon-gray-2);

  transition: background-color 0.2s ease;
}

.fx-ion-button:hover {
  --color: var(--exxon-blue-2);
  overflow: visible;
}

.fluidilogix-flex-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  margin-bottom: 1rem;
}

.fx-ion-button-home {
  --border-radius: 2rem;
  height: 240px;
}

.form-field {
  --background: white;
}
